import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useKeenSlider} from 'keen-slider/react';
import {containerSize, ScreenSizes} from '../../types/responsive';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {Flex} from './Flex';
import {ButtonLink} from './Button';
import {getArrayOfLength} from 'utils/helpers';

import googleLogo from 'images/google_logo.svg';
import starEmpty from 'images/star-empty.svg';
import starFilled from 'images/star-filled.svg';
import starHalf from 'images/star-half.svg';

type Review = {
  author_name: string;
  text: string;
  rating: number;
  profile_photo_url: string;
  relative_time_description: string;
};

export type Place = {
  rating: number;
  reviews: Review[];
  user_ratings_total: string;
};

const ReviewsWrapper = styled.div`
  max-width: ${containerSize};
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 4px 20px;
`;

const Slider = styled.div`
  overflow: hidden;
  position: relative;
  @media (max-width: ${ScreenSizes.md}) {
    overflow: visible !important;
  }
`;

const Wrapper = styled.div<{
  direction?: 'row' | 'column';
  gap?: string;
  justify?:
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-evenly';
}>`
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  position: relative;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: center;
  flex-direction: ${(props) => props.direction || 'row'};
  gap: ${(props) => props.gap || '12px'};
  border: 1px solid #ddd;
  margin-bottom: 1px;
  @media (max-width: ${ScreenSizes.xs}) {
    flex-direction: column;
  }
`;

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
`;

const AuthorName = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;

const Star = styled.div`
  width: 18px;
  height: 18px;
`;

const ReviewText = styled.p`
  font-size: 20px;
  line-height: 1.4;
  margin-top: 12px;
  text-align: center;
  @media (min-width: ${ScreenSizes.sm}) {
    font-size: 18px;
  }
  @media (min-width: ${ScreenSizes.xs}) {
    font-size: 16px;
  }
`;
const ReviewTimeDescription = styled.p`
  overflow: hidden;
  min-width: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.17;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(0, 10, 18, 0.5);
`;

const ArrowContainer = styled.div<{left?: boolean; right?: boolean}>`
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0;
  border-radius: 50%;
  background-color: rgba(17, 17, 17, 0.5);
  box-shadow: rgb(0 0 0 / 30%) 0 4px 12px 0;
  top: calc(50% - 16px);
  left: ${(props) => (props.left ? '6px' : 'unset')};
  right: ${(props) => (props.right ? '6px' : 'unset')};
  z-index: 100;
  cursor: pointer;

  svg {
    position: absolute;
    width: 18px;
    height: 18px;
    left: calc(50% - 9px);
    top: calc(50% - 9px);
    path {
      fill: rgb(255, 255, 255);
    }
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 50%) 0 4px 12px 0;
    background-color: rgba(17, 17, 17);
  }
`;
const SliderArrow: React.FC<
  {left?: boolean; right?: boolean; onClick: () => void} & React.HTMLAttributes<HTMLDivElement>
> = ({left, right, onClick}) => {
  return (
    <ArrowContainer onClick={onClick} left={left} right={right}>
      {left && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 24.1">
          <path fill="currentColor" d="M12.05,24.1l-12-12L12.05,0,14.2,2.15l-9.9,9.9L14.2,22Z" />
        </svg>
      )}
      {right && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 24.1">
          <path fill="currentColor" d="M2.15,24.1,0,22l9.9-9.9L0,2.15,2.15,0l12,12.05Z" />
        </svg>
      )}
    </ArrowContainer>
  );
};

const GoogleLogo = styled.img`
  height: 40px;
  margin: 0;
`;

const Stars: React.FC<{rating: number}> = ({rating}) => {
  return (
    <Flex gap={2}>
      {getArrayOfLength(5, 0).map((_, index) => {
        const module = Math.floor(rating) > index ? 1 : rating % index;
        const svg = module > 1 ? starEmpty : module < 0.7 ? starHalf : starFilled;
        return <Star key={index}>{<img src={svg} alt="star" />}</Star>;
      })}
    </Flex>
  );
};

const GoogleReview: React.FC<{review: Review}> = ({review}) => {
  return (
    <>
      <Wrapper className="keen-slider__slide" direction="column" gap="8px">
        <Avatar src={review.profile_photo_url} />
        <AuthorName>{review.author_name}</AuthorName>
        <Stars rating={review.rating} />
        <ReviewTimeDescription>{review.relative_time_description}</ReviewTimeDescription>
        <ReviewText>{review.text}</ReviewText>
      </Wrapper>
    </>
  );
};

export const GoogleReviews: React.FC<{place: Place} & React.HTMLAttributes<HTMLElement>> = ({
  place
}) => {
  const {t} = useI18next();
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: 'snap',
    slides: {perView: 3, spacing: 18},
    breakpoints: {
      ['(max-width: ' + ScreenSizes.md + ')']: {
        slides: {perView: 2.5, spacing: 16}
      },
      ['(max-width: ' + ScreenSizes.sm + ')']: {
        slides: {perView: 2, spacing: 14}
      },
      ['(max-width: ' + ScreenSizes.xs + ')']: {
        slides: {perView: 1.2, spacing: 12}
      }
    }
  });
  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(true);
  useEffect(() => {
    if (!instanceRef.current) return;
    instanceRef.current.update();
    setArrowsState();
  }, [instanceRef]);

  useEffect(() => {
    if (!instanceRef.current) return;
    instanceRef.current.on('dragEnded', setArrowsState);
  }, [sliderRef]);

  const setArrowsState = () => {
    if (!instanceRef.current?.animator.targetIdx) {
      setHasPrev(false);
      return;
    }
    setHasPrev(instanceRef.current.animator.targetIdx > 0);
    setHasNext(instanceRef.current.animator.targetIdx < instanceRef.current.track.details.maxIdx);
  };

  const reviews: Review[] = [
    {
      author_name: 'Felipe Martin',
      text: t('The best online payment aggregate out there'),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a-/ACB-R5RxCr5OkOmYqNiEQO35eATx5lO8Ps9sPK6Kt8y2MDs=w36-h36-p-c0x00000000-rp-mo-ba3-br100',
      relative_time_description: t('A week ago')
    },
    {
      author_name: 'Juan M',
      text: t('Super intuitive and easy to use'),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a/AGNmyxbQyfYC12xNkNm5tHcqvDyCPxcF9stmDd9H16W5=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('A week ago')
    },
    {
      author_name: 'Beautify Box',
      text: t(
        'We have integrated Monei as our secure payment gateway, in a simple, fast and completely secure way. It also offers us payment methods such as Bizum, which increases the conversion of payments and gives options to all customers.\n' +
          'The commissions with respect to others are lower and also the customer service in Spain is very efficient. Thank you'
      ),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a/AGNmyxYC-YYSw0sT1eVn63jDj7g2HNS3En8IGIxsbG28Mg0=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('A week ago')
    },
    {
      author_name: 'OrtoPrime España',
      text: t(
        '100% recommended. An optimal payment gateway that quickly adapts to the changes and improvements required in electronic commerce. We are very happy to work with the MONEI team!'
      ),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a-/ACB-R5TrYVNx_nz1m0rTppsX0qK24hbtBxjn6kfUnzPL=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('Two weeks ago')
    },
    {
      author_name: 'Biel Tenias Tenas',
      text: t(
        "We use it every day to collect from our firm's clients and I can only recommend it to everyone. VERY LOW commissions and the next day you have the money deposited in your checking account. 10/10."
      ),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a-/ACB-R5SUVhiushEOkfiQN2k1TKpTrU9cJnY7Dsq5WRsH=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('A month ago')
    },
    {
      author_name: 'Mar Sánchez Orduna',
      text: t(
        'I give this application a 10 for my online store since thanks to it my clients can pay with bizum and other means of card payment, increasing my conversion rate. It is very simple and intuitive and anyone can connect their ecommerce to Money. They also have a super responsive support that answers all your questions. Highly recommended.'
      ),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a/AGNmyxYwDW3DLpM961pu0SLBGZLS6pMWI_8mzRUZ81jd=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('A month ago')
    },
    {
      author_name: 'Catherin Barajas guerrero',
      text: t(
        'I use MONEI for my business and so far it works great. It is easy to integrate and the customer service is very good. I recommend it'
      ),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a-/ACB-R5QJzk8rbwd7WvpHkq8aW6e8bOOCQTNuxzifam4a=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('Two months ago')
    },
    {
      author_name: 'Andres Varela',
      text: t('Highly recommended'),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a-/ACB-R5Q8VEEAc0v_rOrPaRTyiNksbXnLmLHkZCsKnb3-FAg=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('Three months ago')
    },
    {
      author_name: 'David Hart Socias',
      text: t(
        'Very good service. Our users asked us to pay for bizum and since we implemented it, the conversion rate of the web has increased!'
      ),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a-/ACB-R5RPVgdSEiqn8FjRCfqtwutGRpmJClptSQnd6J76=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('Four months ago')
    },
    {
      author_name: 'Lluis Cufi',
      text: t(
        "I've been using MONEI apps and MONEI Payments Platform for many years and I have only good words for them. The service is absolutely perfect, great customer service and very reasonable prices. I totally recommend it!"
      ),
      rating: 5,
      profile_photo_url:
        'https://lh3.googleusercontent.com/a/AGNmyxZOH76WVSCWGUn0EoHOISsCJpd5y4n1Xt3sXSdD=w36-h36-p-c0x00000000-rp-mo-br100',
      relative_time_description: t('Four months ago')
    }
  ];

  const moneiMapsUrl =
    'https://www.google.com/maps/place/MONEI/@36.7178642,-4.4395234,17z/data=!3m1!4b1!4m5!3m4!1s0xd72f92b70e492a5:0x4ae19b976b55821d!8m2!3d36.7178642!4d-4.4395234';
  return (
    <ReviewsWrapper>
      <Wrapper justify="space-between">
        <Flex direction="column">
          <Flex direction="row" alignItems="center" gap={8}>
            <GoogleLogo src={googleLogo} />
            <span>Rating</span>
          </Flex>
          <Flex direction="row" alignItems="center" gap={8}>
            {place.user_ratings_total} <Trans>reviews</Trans>
            <Stars rating={place.rating} />
          </Flex>
        </Flex>
        <ButtonLink variant="light" href={moneiMapsUrl} target="_blank">
          <Trans>Write a review</Trans>
        </ButtonLink>
      </Wrapper>
      <Slider
        ref={sliderRef}
        className="keen-slider"
        style={{margin: 0, display: 'flex', flexDirection: 'row'}}>
        {hasPrev && (
          <SliderArrow
            left
            onClick={() => {
              instanceRef.current?.prev();
              setArrowsState();
            }}
          />
        )}
        {reviews.map((review) => {
          return <GoogleReview review={review} />;
        })}
        {hasNext && (
          <SliderArrow
            right
            onClick={() => {
              instanceRef.current?.next();
              setArrowsState();
            }}
          />
        )}
      </Slider>
    </ReviewsWrapper>
  );
};
